.mainDiv{
  min-height: 500px;
}

.center {
  justify-content: center;
  align-items: center;
}

smart_button {
  text-transform: none;
  }

.container {
    width: 1400px;
    max-width: 100%;
    margin: auto;
    overflow: auto;
    min-height: 300px;
    background-color: #faf8f2;
    padding: 30px;
    padding-top: 60px;
    border-radius: 5px;
    position: relative;
    box-shadow: 0px 4px 11px 0px rgb(0 0 0 / 14%);
  }

.login_page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .login_page h1, p {
    text-align: center;
  }
  
  .login_page p {
    margin-bottom: 24px;
  }
  
  .login_page .login_type {
    text-align: center;
  
  }
  
  .login_page .add_form.login {
    width: 600px;
    max-width: 100%;
  }
  
  .login_page .add_form .error {
    text-align: center;
    color: red;
    margin-top: 8px;
  }
  
  .login_page p.forgot_password {
    margin-top: 2px;
    color: #0000EE;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
  }
  
  .login_page.container button.btn.selected    {
    background: #3e3e3e;
    color: #fff;
  }
  
  .login_page.container button.btn.login    {
    background: #3e3e3e;
    color: #fff;
  }

  .form_control {
    margin: 20px 0;
  }
  .form_control label {
    display: block;
  }
  
  .form_control-check {
    display: flex;
    align-items: center;
  }
  .form_control-check label {
    flex: 1;
  }
  .form_control-check input {
    flex: 2;
    height: 20px;
  }
  .form_control input[type="checkbox"] {
    width: auto;
    flex: unset;
    height: 20px;
    margin-right: 20px;
  }
  