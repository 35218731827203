.flexRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .imgX {
    height: 80px;
    width: 80px;
    object-fit: contain;
    /* background-color:#000000; */
  }