.row_style {
    /* background-color: #D7D3D3;   */
    /* padding: 0 !important; */
    /* border-bottom: 0 !important; */
    border-collapse: collapse !important;
  }

  .cell_style {
    /* background-color: #a9f6c4;   */
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .cell_style_left {
    /* background-color: #a9f6c4;   */
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-bottom: 2 !important;
    color: #3a6186 !important;
  }

  .cell_style_right {
    /* background-color: #a9f6c4;   */
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-bottom: 2 !important;
    display: flex;
    flex-direction: row;
  }

  .imgX {
    height: 80px;
    width: 80px;
    object-fit: contain;
    /* background-color:#000000; */
  }

  .margin_0{
    margin-top: 0ch;
    margin-bottom: 0ch;
  }

  .flexRow{
    display: flex;
    flex-direction: row;
  }

  .text_align_center{
    text-align: center;
}

  .modal_main{
    position: 'absolute';
    top: '50%';
    left: '50%';
    transform: 'translate(-50%, -50%)';
    width: 400;
    background-color: '#FFFFFF';
    border: '2px solid #000';
    box-shadow: 24;
    padding: 4;
  }

  .weak {
    font-weight: lighter;
    font-size: 13px;
}

.preceedingHead {
  /* font-weight: lighter; */
  font-size: 10 !important;
  color: darkgray !important;
}

.marginRight2{
  margin-right: 2px;
}

a:link {
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
}