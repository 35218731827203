.main_div{
    min-height: 600px;
    padding-left: 5px;
    padding-left: 5px;
    }

    .alertDiv{
        margin-bottom: 4px;
    }

    .div200{
        min-height: 200;
    }

    .text_align_center{
        text-align: center;
    }

    .imgX {
        width: 100px;
        height: 100px;
        object-fit: contain;
        background-color:#000000;
      }

      .imgX2 {
          width: 80px;
          height: 80px;
          object-fit: contain;
          /* background-color:#000000; */
        }

      .margin_0{
        margin-top: 0ch;
        margin-bottom: 0ch;
      }

      .flexRow{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .img{
        border-radius: 3px;
    }