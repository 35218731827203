.row_style {
    /* background-color: #D7D3D3;   */
    /* padding: 0 !important; */
    /* border-bottom: 0 !important; */
    border-collapse: collapse !important;
  }

  .cell_style_left {
    /* background-color: #a9f6c4;   */
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-bottom: 2 !important;
    color: #3a6186 !important;
  }

  .cell_style_right {
    /* background-color: #a9f6c4;   */
    padding-top: 0.5 !important;
    padding-bottom: 0 !important;
    border-bottom: 2 !important;
    display: flex;
    flex-direction: row;
    text-align: left;
  }

  .imgX {
    height: 80px;
    width: 80px;
    object-fit: contain;
    margin-top: 1 !important;
    /* background-color:#000000; */
  }
  
  .imgXX {
    width: 80px;
  }