.mainDiv {
    min-height: 500px;
    margin-top: 2px;
    padding: 3px;
}

.img{
    border-radius: 3px;
}

.imgX {
    object-fit: contain;
    /* background-color:#000000; */
  }

.marquee {
    height: auto;
}

.red{
    color: #FF3131;
}

.blue{
    color: #1F51FF
}

.imgAd {
    max-width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    object-fit: 'contain';
    /* object-Fit: 'cover'; */
  }

  .glass_style{
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    background: rgba(0,0,0,0.7);
    backdrop-filter: blur( 10px );
  }