.footer {
    height: 20px; 
    display: flex;
    background-color:#000000;
    position: sticky;
    top: 60;
    z-index: 100;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.footerText {
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
}