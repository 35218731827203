.main_div{
    min-height: 600px;
    padding-left: 5px;
    }

  .imgAd {
    max-width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    object-fit: 'contain';
    /* object-Fit: 'cover'; */
  }

