.main_div{
    min-height: 600px;
    padding-left: 5px;
    }

.country {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    margin-bottom: 3px;
    }

    .country__details {
        /* padding: 2px 20px; */
        display: flex;
        flex-direction: column;
        width: 150px;
        padding-left: 3px;
        }
    
    .country__name {
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 0px;
        margin-top: 2px;
        padding:  0;
        }
    
    .country__capital {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0px;
        margin-top: 0cqmin;
        padding:  0;
        color: dimgrey;
        text-align: left;
        }

.statusOk {
color: #1cab47;
font-size: 14px;
}

.statusFalse {
color: crimson;
font-size: 14px;
}

.editButtons {
    display: flex;
    flex-direction: column;
    margin-left: auto;
}

.state__details {
    /* padding: 2px 20px; */
    display: flex;
    flex-direction: column;
    width: 150px;
    padding-left: 3px;
    }
    
.state__name {
    font-size: 16px;
    line-height: 17px;
    margin-bottom: 0px;
    margin-top: 2px;
    padding:  0;
    }
    
.state__capital {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0px;
    margin-top: 0cqmin;
    padding:  0;
    color: dimgrey;
    text-align: left;
    }

.alertDiv{
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right: 4px;
}

.div200{
    min-height: 200px;
}

.link {
    /* color: yellowgreen; */
    text-decoration: none;
    background-color: rgb(229, 237, 237);
    padding-left: 1ch;
    padding-right: 1ch;
    padding-bottom: 0.25ch;
    border-radius: 0.25ch;
}
 
/* Changing the color */
.link:hover {
    color: red;
}

.link:active {
    background-color: aquamarine;
  }

.link2 {
    color: inherit;
    background-color: rgb(180, 105, 139);
    padding-left: 1ch;
    padding-right: 1ch;
    padding-bottom: 0.25ch;
    border-radius: 0.25ch;
    /* padding: 10px;
    font-size: 1.4rem; */
}

/* .link2:hover {
    color: red;
} */

.pagerContainer{
    display: flex;
    flex-direction: row;
    
}

.pagerNavigator{
    margin-left: auto;
}