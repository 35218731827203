.button_style{
  background: rgba( 50, 50, 50, .8 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  border-radius: 1px;
}

.top_to_btm{
    position: relative;
  }

  .icon_position{
    /* position: fixed;
    top: 65px;
    right: 25px;
    z-index: 20; */

    position: fixed;
    bottom: 40px;
    left: 25px;
    z-index: 20;
  }

  .icon_position_right{
    /* position: fixed;
    top: 65px;
    right: 25px;
    z-index: 20; */

    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
  }

  .icon_style{
    /* background-color: #551B54; */
    /* border: 2px solid #fff; */
    /* background: rgba(252, 248, 241, 0.8); */
    background: #CFFF04;
    /* color: #CFFF04; */
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
    /* box-shadow: 2px 2px 5px #CFFF04; */
    padding: 4px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    background: rgba(89, 131, 83, 0.09);
    backdrop-filter: blur( 10px );
    /* border-radius: 1em; */
  }

  .icon_style:hover{
    animation: none;
    /* background: #ffa0d6; */
    /* color: #551B54; */
    /* border: 2px solid #551B54; */
  }

  .icon_style_2{
    /* background-color: #551B54; */
    /* border: 2px solid #fff; */
    /* background: rgba(252, 248, 241, 0.8); */
    background: #CFFF04;
    /* color: #CFFF04; */
    cursor: pointer;
    /* animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out; */
    /* box-shadow: 2px 2px 5px #CFFF04; */
    padding: 4px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    background: rgba(89, 131, 83, 0.09);
    backdrop-filter: blur( 10px );
    /* border-radius: 1em; */
  }

  @keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    25%{
      transform: translateY(20px);
    }
    50%{
      transform: translateY(0px);
    }
    75%{
      transform: translateY(-20px);
    }
    100%{
      transform: translateY(0px);
    }
  }