.main_div{
    min-height: 600px;
    }

    .button_orange {
        color: '#FF5F1F';
        background-color: 'white';
    }

    .alertDiv{
        margin-bottom: 4px;
    }

    .div200{
        min-height: 200px;
    }