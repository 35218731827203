/* Pagination styling */
.pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  margin-top: 20px;
}

.pagination li {
  margin: 0 5px;
}

.pagination li a {
  display: inline-block;
  padding: 10px 15px;
  background-color: white;
  color: #3a6186;
  border: 1px solid #89253e;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.pagination li a:hover {
  /* background-color: #007bff; */
  background-image: linear-gradient(to right, #3a6186, #89253e);
  color: white;
}

.pagination li.active a {
  background-image: linear-gradient(to right, #3a6186, #89253e);
  color: white;
  border: 1px solid #89253e;
  border-radius: 5px;
  text-decoration: none;
  border-color: #89253e;
}

.active {
  background-color: #3a6186;
  color: white;
  border: 1px solid #89253e;
  border-radius: 5px;
  text-decoration: none;
}
