.main_div{
  min-height: 600px;
  padding-left: 5px;
  padding-left: 5px;
  }

.table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .td, .th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  
  
  .tr:nth-child(odd) {
    background-color: #dddddd;
  }
  
  .th {
    color: #fff;
    background-color: #4d4d4d;
  }

  .imgX2 {
    /* width: 80px; */
    max-width: 40px;
    /* height: 60px; */
    max-height: 40px;
    object-fit: contain;
    /* background-color:#000000; */
    border-radius: 1px;
    margin-right: 10px;
  }

  .imgX3 {
    /* width: 80px; */
    max-width: 25px;
    /* height: 60px; */
    max-height: 25px;
    object-fit: contain;
    /* background-color:#000000; */
    border-radius: 1px;
    margin-right: 5px;
  }

  .overrideUnderline {
    text-decoration: none;
  }

  .flexRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .margin_top_one{
    margin-top: 10px;
    text-align: center;
  }
  
  .imgX {
    height: 50px;
    width: 50px;
    object-fit: contain;
    /* background-color:#000000; */
  }
  
  .imgXX {
    width: 80px;
  }