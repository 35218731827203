.flexRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .imgX {
    max-height: 50px;
    /* max-width: 80px; */
    object-fit: contain;
    /* background-color:#000000; */
  }