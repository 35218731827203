.margin_0{
    margin-top: 0ch;
    margin-bottom: 0ch;
  }

  .flexRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .text_align_center{
    text-align: center;
}

.margin_top_one{
  margin-top: 10px;
  text-align: center;
}

.imgX {
  height: 80px;
  width: 80px;
  object-fit: contain;
  /* background-color:#000000; */
}