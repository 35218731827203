.main_div{
    min-height: 600px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
    background-color: rgb(246, 246, 244);

    }

    .list_section_1 {
        /* padding: 2px 20px; */
        display: flex;
        flex-direction: column;
        width: 200px;
        padding-left: 3px;
        }
    
    .list_section_1_name {
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 0px;
        margin-top: 2px;
        padding:  0;
        font-weight: 600;
        }
    
    .list_section_1_nameAlt {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0px;
        margin-top: 0cqmin;
        padding:  0;
        color: dimgrey;
        text-align: left;
        }
    
    .statusOk {
    color: #1cab47;
    font-size: 14px;
    }
    
    .statusFalse {
    color: crimson;
    font-size: 14px;
    }

    .editButtonsDiv {
        display: flex;
        flex-direction: column;
        margin-left: auto;
    }

    .alertDiv{
        margin-bottom: 4px;
    }

    .div200{
        min-height: 200px;
    }

    .link {
        /* color: yellowgreen; */
        text-decoration: none;
        background-color: rgb(229, 237, 237);
        padding-left: 1ch;
        padding-right: 1ch;
        padding-bottom: 0.25ch;
        border-radius: 0.25ch;
    }
     
    /* Changing the color */
    .link:hover {
        color: red;
    }
    
    .link:active {
        background-color: aquamarine;
      }
    
    .link2 {
        /* color: inherit; */
        background-color: rgb(180, 105, 139);
        padding-left: 1ch;
        padding-right: 1ch;
        padding-bottom: 0.25ch;
        border-radius: 0.25ch;
        /* padding: 10px;
        font-size: 1.4rem; */
    }