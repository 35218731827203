.main_div{
    min-height: 600px;
    }

    
.country__cover {
    display: flex;
    flex-direction: row;
    }

.forLegis__button {
    background-color: #1cab47;
    }

.first__details {
    /* padding: 2px 20px; */
    display: flex;
    flex-direction: column;
    width: 150px;
    padding-left: 3px;
    }

    

.first__name {
    font-size: 16px;
    line-height: 17px;
    margin-bottom: 0px;
    margin-top: 2px;
    padding:  0;
    }

.second_coloumn {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0px;
    margin-top: 0cqmin;
    padding:  0;
    text-align: left;
    }

 /* .property_header {
color: hsl(52, 12%, 46%);
font-size: 13px;
}
.property_main {
    font-size: 15px;
    }

.flex_coloumn {
    display: flex;
    flex-direction: column;
    }
.flex_row {
    display: flex;
    flex-direction: row;
    } */

.statusFalse {
color: crimson;
font-size: 14px;
}

.flagContainer {
    min-width: 150px;
    max-width: 150px;
    padding: 0;
}

.country__flag {
    height: 50px;
    
    }

.editButtons {
    display: flex;
    flex-direction: column;
    margin-left: auto;
}

