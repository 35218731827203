.header {
    height: 60px; 
    display: flex;
    align-items: center;
    background: linear-gradient(to right, #323135, #e36363, #323135);
    /* background: linear-gradient(to right, #48464c, #e36363, #48464c); */
    /* animation: gradient_anim 23s ease infinite; */
    position: sticky;
    top: 0;
    z-index: 100;
    justify-content: space-between;
}

.logo {
    height: 40px;
    border-radius: 5px;
}

.subHeader {
    /* height: 20px;  */
    display: flex;
    background-color:#d6d6d6;
    position: sticky;
    top: 40;
    font-size: 25px;
    /* z-index: 100; */
    /* color: #FFFFFF; */
}

.subHeaderText {
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    text-align: center;
}

@keyframes gradient_anim {
    0% {
      background-position: 50%, 0% 50%;
    }
    50% {
      background-position: 0%, 100% 50%;
    }
    100% {
      background-position: 0% 50%, 100%;
    }
  }

.header__logo {
    display: flex;
    align-items: center;
    color: #EDC970;
    margin: 0 25px;
}

.header__logoImage {
    margin-right: 10px;
}

.header__logoTitle {
    margin-left: 10px;
    font-size: 30px;
    font-weight: 800; 
    white-space: nowrap;
}

.header__search {
    display: flex;
    flex: 1;
    align-items: center;
    border-radius: 24px;
    margin: 0 10px;
}

.header__searchInput {
    height: 12px;
    padding: 10px;
    border: none;
    width: 100%;
}

.header__searchIcon {
    padding: 5px;
    height: 22px !important;
    background-color: #ff9f00;
}

.header__nav {
    display: flex;
    justify-content: space-evenly;
}

.nav__item {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;  
    color: white;
}

.nav__itemLineOne {
    font-size: 10px;
}

.nav__itemLineTwo {
    font-size: 13px;
    font-weight: 800; 
    color: #FFFFFF;
    text-align: center;
}

.nav__itemLineTwo_Two {
    font-size: 10px;
    font-weight: 800; 
    color: #FFFFFF;
    cursor: pointer;
    line-height: 1.5;
}

.nav__itemLineTwo_Three {
    font-weight: 600; 
    color: #FFFFFF;
    cursor: pointer;
}

.nav__itemBasket {
    display: flex;
    align-items: center;
    color: white;
    margin-right: 50px;
    margin-left: 10px;
}

.nav__basketCount {
    margin-left: 10px;
    margin-right: 10px;
}

.btn_transparent {
    background-color: #00000000;
}