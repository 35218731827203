.imgX {
    height: 50px;
    width: 50px;
    object-fit: contain;
    /* background-color:#000000; */
  }

  .imgX2 {
    /* width: 80px; */
    max-width: 40px;
    /* height: 60px; */
    max-height: 40px;
    object-fit: contain;
    /* background-color:#000000; */
    border-radius: 1px;
    margin-right: 10px;
  }

  
  .imgX3 {
    height: 30px;
    width: 30px;
    object-fit: contain;
    /* background-color:#000000; */
  }
  
  .imgXX {
    width: 80px;
  }